<template>
    <div id="main-wrapper">
      <Header :is-redirect="false"></Header>
      <Section class="fixed auth-frame">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-sm-12 mx-auto">
              <common-message :title="title" :message="message"></common-message>
            </div>
          </div>
        </div>
      </Section>
      <Footer></Footer>
    </div>
</template>

<script>
    import Footer from "@/views/components/Footer";
    import Header from "@/views/components/Header";
    import MixIn from "@/common/mixin";
    import CommonMessage from "@/views/components/CommonMessage";
    import Vue from "vue";
    export default {
        name: "Result",
        components: {Footer, Header, CommonMessage},
        mixin: {MixIn},
        data: function () {
            return {
                title: Vue.prototype.$result ? Vue.prototype.$result.title : "",
                message: Vue.prototype.$result ? Vue.prototype.$result.message : ""
            }
        },
        created() {
            if (!Vue.prototype.$result) {
                this.$router.push({ path: `${this.$route.params['path']}/error` });
                return;
            }
            Vue.prototype.$result = undefined;
        }
    }
</script>

<style scoped>

</style>
