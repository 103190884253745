<template>
    <div class="center">
        <h1 class="common-title">{{title}}</h1>
        <p class="common-message">{{message}}</p>
        <router-link class="btn-md full-width pop-login top_return_btn form-button" to="/">トップへ戻る</router-link>
    </div>
</template>

<script>
    export default {
        name: "CommonMessage",
        props: {
            title: {
                type: String,
                default: ""
            },
            message: {
                type: String,
                default: "",
            }
        }
    }
</script>

<style scoped>

</style>
