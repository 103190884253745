import { render, staticRenderFns } from "./RegisterCompany.vue?vue&type=template&id=492d0018&scoped=true&"
import script from "./RegisterCompany.vue?vue&type=script&lang=js&"
export * from "./RegisterCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492d0018",
  null
  
)

export default component.exports