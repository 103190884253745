<template>
  <section class="fixed mypage-contents-fixed">
    <div class="container">
      <div class="row">
        <div class="col-12 mx-auto" style="padding: 0">
          <div class="form-title-group">
            <div class="menu-active-mark" style="margin-top: 10px;"/>
            <div class="form-title">
              <h1 v-if="isEdit">企業編集</h1>
              <h1 v-else>企業登録</h1>
            </div>
            <button class="mypage-title-menu">マイページメニュー</button>
          </div>
          <div class="menu-bar"/>
          <p v-if="isEdit" class="form-text">企業情報の編集ができます。「企業一覧表示」で「表示する」ステータスを選択すると企業リストに掲載されます。</p>
          <p v-else class="form-text">企業登録ができます。「企業一覧表示」で「表示する」ステータスを選択すると企業リストに掲載されます。</p>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>会社名</label>
              <p class="required-label">必須</p>
            </div>
            <input type="text" class="form-control" placeholder="株式会社サンプル" v-model="name">
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>Webサイト</label>
              <p class="non-required-label"></p>
            </div>
            <input type="url" class="form-control" placeholder="https://sesmedia.jp/" v-model="url">
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>メールアドレス</label>
              <p class="non-required-label"></p>
            </div>
            <input type="email" class="form-control" placeholder="sesmedia@yurulica.com" v-model="mailAddress">
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>電話番号</label>
              <p class="non-required-label"></p>
            </div>
            <input class="form-control" placeholder="0312345678" v-model="phoneNumber">
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>住所</label>
              <p class="required-label">必須</p>
            </div>
            <input class="form-control" placeholder="東京都渋谷区1-1-1 サンプルビル101" v-model="address">
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>地域・エリア</label>
              <p class="required-label">必須</p>
            </div>
            <select v-model="area" class="form-control custom-select">
              <option v-for="areaType of areas" :key="areaType" :value="areaType">{{getAreaText(areaType)}}</option>
            </select>
          </div>
          <div v-if="isKantoArea">
            <div class="form-group">
              <div class="form-group-title-group">
                <label>関東エリア</label>
                <p class="non-required-label"></p>
              </div>
              <select v-model="subArea" class="form-control custom-select">
                <option v-for="subAreaType of subAreas" :key="subAreaType" :value="subAreaType">{{getSubAreaText(subAreaType)}}</option>
              </select>
            </div>
          </div>
          <div>
            <div class="form-group">
              <div class="form-group-title-group">
                <label>設立年</label>
                <p class="non-required-label"></p>
              </div>
              <div class="form-contents-pair">
                <input class="form-control" placeholder="2020" v-model="foundedYear"><p>年</p>
            </div>
          </div>
          </div>
          <div>
            <div class="form-group">
              <div class="form-group-title-group">
                <label>資本金</label>
                <p class="non-required-label"></p>
              </div>
              <div class="form-contents-pair">
                <input class="form-control" placeholder="1000000" v-model="capital"><p>円</p>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>従業員数</label>
              <p class="non-required-label"></p>
            </div>
            <div class="form-contents-pair">
              <input class="form-control" placeholder="1000" v-model="employeeNum"><p>人</p>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>支店・支社</label>
              <p class="required-label">必須</p>
            </div>
            <div class="form-control form-select">
              <ul class="no-ul-list">
                <li class="inline_block">
                  <input id="g0_0" class="radio-custom" name="Branch" type="radio" v-model="isBranchOffice" v-bind:value="true">
                  <label for="g0_0" class="radio-custom-label weak">あり</label>
                </li>
                <li class="inline_block">
                  <input id="g1_0" class="radio-custom" name="Branch" type="radio" v-model="isBranchOffice" v-bind:value="false">
                  <label for="g1_0" class="radio-custom-label weak">なし</label>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>一般派遣免許</label>
              <p class="required-label">必須</p>
            </div>
            <div class="form-control form-select">
              <ul class="no-ul-list">
                <li class="inline_block">
                  <input id="h0_0" class="radio-custom" name="DispatchLicense" type="radio" v-model="isDispatchLicense" v-bind:value="true">
                  <label for="h0_0" class="radio-custom-label weak">あり</label>
                </li>
                <li class="inline_block">
                  <input id="h1_0" class="radio-custom" name="DispatchLicense" type="radio" v-model="isDispatchLicense" v-bind:value="false">
                  <label for="h1_0" class="radio-custom-label weak">なし</label>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>パートナー募集</label>
              <p class="required-label">必須</p>
            </div>
            <div class="form-control form-select" style="height: auto; margin: 0;">
              <ul class="no-ul-list">
                <li class="inline_block">
                  <input id="i0_0" class="radio-custom" name="recruitmentStatus" value="1" type="radio" v-model="recruitmentStatus">
                  <label for="i0_0" class="radio-custom-label weak">パートナー積極募集中</label>
                </li>
                <li class="inline_block">
                  <input id="i1_0" class="radio-custom" name="recruitmentStatus" value="2" type="radio" v-model="recruitmentStatus">
                  <label for="i1_0" class="radio-custom-label weak">案件に強いパートナー募集中</label>
                </li>
                <li class="inline_block">
                  <input id="i2_0" class="radio-custom" name="recruitmentStatus" value="3" type="radio" v-model="recruitmentStatus">
                  <label for="i2_0" class="radio-custom-label weak">要員に強いパートナー募集中</label>
                </li>
                <li class="inline_block">
                  <input id="i3_0" class="radio-custom" name="recruitmentStatus" value="4" type="radio" v-model="recruitmentStatus">
                  <label for="i3_0" class="radio-custom-label weak">パートナー募集停止中</label>
                </li>
              </ul>
            </div>
          </div>

          <div class="form-group">
            <div class="form-group-title-group">
              <label>企業の特徴</label>
              <p class="non-required-label"></p>
            </div>
            <div class="form-control form-select" style="height: auto; margin: 0;">
              <ul class="no-ul-list" style="flex-wrap: wrap; display: flex;">
                <li>
                  <input id="b0_0" class="checkbox-custom" type="checkbox" value="0" v-model="companyFeatures">
                  <label for="b0_0" class="checkbox-custom-label weak form-select-2">エンド直案件あり</label>
                </li>
                <li>
                  <input id="b1_0" class="checkbox-custom" type="checkbox" value="1" v-model="companyFeatures">
                  <label for="b1_0" class="checkbox-custom-label weak form-select-2">元請け直案件あり</label>
                </li>
                <li>
                  <input id="b2_0" class="checkbox-custom" type="checkbox" value="2" v-model="companyFeatures">
                  <label for="b2_0" class="checkbox-custom-label weak form-select-2">ロースキル案件あり</label>
                </li>
                <li>
                  <input id="b3_0" class="checkbox-custom" type="checkbox" value="3" v-model="companyFeatures">
                  <label for="b3_0" class="checkbox-custom-label weak form-select-2">自社内・グループ会社内案件あり</label>
                </li>
                <li>
                  <input id="b4_0" class="checkbox-custom" type="checkbox" value="4" v-model="companyFeatures">
                  <label for="b4_0" class="checkbox-custom-label weak form-select-2">社員・プロパー営業あり</label>
                </li>
                <li>
                  <input id="b5_0" class="checkbox-custom" type="checkbox" value="5" v-model="companyFeatures">
                  <label for="b5_0" class="checkbox-custom-label weak form-select-2">直フリーランスの扱いあり</label>
                </li>
                <li>
                  <input id="b6_0" class="checkbox-custom" type="checkbox" value="6" v-model="companyFeatures">
                  <label for="b6_0" class="checkbox-custom-label weak form-select-2">業務系に強い</label>
                </li>
                <li>
                  <input id="b7_0" class="checkbox-custom" type="checkbox" value="7" v-model="companyFeatures">
                  <label for="b7_0" class="checkbox-custom-label weak form-select-2">Web系に強い</label>
                </li>
                <li>
                  <input id="b8_0" class="checkbox-custom" type="checkbox" value="8" v-model="companyFeatures">
                  <label for="b8_0" class="checkbox-custom-label weak form-select-2">ゲーム系に強い</label>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group">
            <div class="form-group-title-group">
              <label>企業一覧表示</label>
              <p class="required-label">必須</p>
            </div>
            <div class="form-control form-select">
              <ul class="no-ul-list">
                <li class="inline_block">
                  <input id="v0_0" class="radio-custom" name="Visible" type="radio" v-model="isVisible" v-bind:value="true">
                  <label for="v0_0" class="radio-custom-label weak">表示する</label>
                </li>
                <li class="inline_block">
                  <input id="v1_0" class="radio-custom" name="Visible" type="radio" v-model="isVisible" v-bind:value="false">
                  <label for="v1_0" class="radio-custom-label weak">表示しない</label>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="hasValidationError" class="error-area">
            <template v-for="(value, key) in validationErrors">
              <p v-for="item in value" :key="key + item.validationText">
                ・{{"(" + getFormName(key) + ")" + item.validationText}}
              </p>
            </template>
          </div>
          <p v-if="isChanged" class="result-text-success">企業情報の更新が完了しました。</p>
          <div class="form-group">
            <button type="submit" class="btn-md full-width pop-login form-button orange-btn" style="width: 100%; margin:20px 0 0 0;" :disabled="!isRegisterEnabled" @click="onClickSendButton">{{isEdit ? "企業情報を更新する" : "企業情報を登録する"}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

    const formMapping = {
        name: "会社名",
        url: "コーポレートサイト",
        mailAddress: "メールアドレス",
        phoneNumber: "電話番号",
        address: "住所",
        area: "地域・エリア",
        subArea: "関東エリア",
        foundedYear: "設立年",
        capital: "資本金",
        employeeNum: "従業員数",
        isBranchOffice: "支店・支社有無",
        isDispatchLicense: "一般派遣免許有無",
        recruitmentStatus: "パートナー募集状況",
        companyFeatures: "企業の特徴",
        isVisible: "企業一覧への表示"
    };

    const Area = {
        Kanto: 0, //関東
        Tokai: 1, //東海
        Kansai: 2, // 関西
        Kyushu: 3, // 九州
        Other: 4 // その他
    }

    const SubArea = {
        Tokyo23Wards: 0, // 東京23区
        OtherTokyo: 1, // 東京23区外
        Kanagawa: 2, // 神奈川
        Chiba: 3, // 千葉
        Saitama: 4, // 埼玉
        Ibaraki: 5, // 茨城
        Gunma: 6,// 群馬
    }

    export default {
        name: "CompanyForm",
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            companyData: {
                type: Object,
                default: () => {}
            },
            validationErrors: {
                type: Object,
                default: () => {}
            },
            isChanged: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                name: this.companyData.name || "",
                url: this.companyData.url || "",
                mailAddress: this.companyData.mailAddress || "",
                phoneNumber: this.companyData.phoneNumber || "",
                address: this.companyData.address || "",
                area: this.companyData.area === undefined ? Area.Kanto : this.companyData.area,
                subArea: this.companyData.subArea === undefined ? SubArea.Tokyo23Wards : this.companyData.subArea,
                foundedYear: this.companyData.foundedYear,
                capital: this.companyData.capital,
                employeeNum: this.companyData.employeeNum,
                isBranchOffice: this.companyData.isBranchOffice || false,
                isDispatchLicense: this.companyData.isDispatchLicense || false,
                companyFeatures: Array.isArray(this.companyData.companyFeatures) ? this.companyData.companyFeatures.map(companyFeature => companyFeature.feature) : [],
                recruitmentStatus: this.companyData.recruitmentStatus || "1",
                isVisible: this.companyData.isVisible === undefined ? true : this.companyData.isVisible
            }
        },

        computed: {
            isRegisterEnabled: {
                get() {
                    return true;
                    // return this.name && this.address && this.area !== undefined;
                },
            },
            areas: {
                get() {
                    return Object.values(Area);
                }
            },
            subAreas: {
                get() {
                    return Object.values(SubArea);
                }
            },
            isKantoArea: {
                get() {
                    return this.area === Area.Kanto;
                }
            },
            hasValidationError: {
                get() {
                    return Object.values(this.validationErrors).length > 0;
                }
            }
        },
        methods: {
            getFormName: function (key) {
                return formMapping[key];
            },
            getAreaText: function (area) {
                switch (area) {
                    case Area.Kanto:
                        return "関東"
                    case Area.Tokai:
                        return "東海"
                    case Area.Kansai:
                        return "関西"
                    case Area.Kyushu:
                        return "九州"
                    case Area.Other:
                        return "その他"
                }
                return "";
            },
            getSubAreaText: function (subArea) {
                switch (subArea) {
                    case SubArea.Tokyo23Wards:
                        return "東京23区"
                    case SubArea.OtherTokyo:
                        return "東京23区外"
                    case SubArea.Kanagawa:
                        return "神奈川"
                    case SubArea.Chiba:
                        return "千葉"
                    case SubArea.Saitama:
                        return "埼玉"
                    case SubArea.Ibaraki:
                        return "茨城"
                    case SubArea.Gunma:
                        return "群馬"
                }
                return "";
            },
            async onClickSendButton() {
                this.$emit("on-click-send-button", {
                    name: this.name,
                    url: this.url,
                    mailAddress: this.mailAddress,
                    phoneNumber: this.phoneNumber === "" ? undefined : this.phoneNumber,
                    address: this.address,
                    area: this.area,
                    subArea: this.area != Area.Kanto ? undefined : this.subArea,
                    foundedYear: this.foundedYear === "" ? undefined : this.foundedYear,
                    capital: this.capital === "" ? undefined : this.capital,
                    employeeNum: this.employeeNum === "" ? undefined : this.employeeNum,
                    isBranchOffice: this.isBranchOffice,
                    isDispatchLicense: this.isDispatchLicense,
                    recruitmentStatus: Number(this.recruitmentStatus),
                    companyFeatures: this.companyFeatures,
                    isVisible: this.isVisible,
                });
            }
        }
    }
</script>

<style scoped>

</style>
